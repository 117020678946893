// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerificationCodeField_container__CCk6k {
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: center;
}


.VerificationCodeField_digitField__3F4ws {
    width: 40px;
    height: 40px;
    outline: none;
    display: flex;
    align-items: flex-end;
    text-align: left !important;
    color: rgba(48, 48, 48, 0.8);
    border: 1px solid #C4C5CA;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 13px 9px 12px;
}

.VerificationCodeField_digitField__3F4ws:focus::placeholder {
    color: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/VerificationCodeField/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,uBAAuB;AAC3B;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,aAAa;IACb,qBAAqB;IACrB,2BAA2B;IAC3B,4BAA4B;IAC5B,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    gap: 6px;\n    justify-content: center;\n}\n\n\n.digitField {\n    width: 40px;\n    height: 40px;\n    outline: none;\n    display: flex;\n    align-items: flex-end;\n    text-align: left !important;\n    color: rgba(48, 48, 48, 0.8);\n    border: 1px solid #C4C5CA;\n    border-radius: 8px;\n    box-sizing: border-box;\n    padding: 10px 13px 9px 12px;\n}\n\n.digitField:focus::placeholder {\n    color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VerificationCodeField_container__CCk6k`,
	"digitField": `VerificationCodeField_digitField__3F4ws`
};
export default ___CSS_LOADER_EXPORT___;
